const imagesdata = [
  {
    id: 1,
    src: "bg-logo1.png",
    srcone: "formatone.png",
    alt: "Image 1",
    title: "All Sport Play Ab Sirf Dream Nahi Ek Mauka hai",
    semiTitle: "#Apni Sapnon ko haqiqat mein badlo",
    logo: "group.png",
    peoples: "2 Million+",
    registrations: "Registtrations",
    logoone: "shield.png",
    peoplesone: "2 Crore+",
    registrationsone: "In winnings Everyday",
    logotwo: "cup.png",
    peoplestwo: "100%",
    registrationstwo: "Safe & Secure",
    paddingTops: "20px",
    paddingBottoms: "17px",
    srcsmall: "smalllogothree.png",
  },
  {
    id: 2,
    src: "bg-logo2.png",
    srcone: "formattwo.png",
    alt: "Image 2 ",
    title:
      "All Sport Play Offers More Than Just Dreams, It's a Real Opportunity!",
    semiTitle: "#Apni Sapnon ko haqiqat mein badlo",

    logo: "group.png",
    peoples: "2 Million+",
    registrations: "Registtrations",
    logoone: "shield.png",
    peoplesone: "2 Crore+",
    registrationsone: "In winnings Everyday",
    logotwo: "cup.png",
    peoplestwo: "100%",
    registrationstwo: "Safe & Secure",
    paddingTops: "19px",
    paddingBottoms: "0px",
    srcsmall: "smalllogotwo.png",
  },
  {
    id: 3,
    src: "bg-logo3.png",
    srcone: "formatthree.png",
    alt: "Image 3",
    title: "All Sport Play Opens Doors Beyond Dreams, It's Your Time to Excel!",
    semiTitle: "#Apni Sapnon ko haqiqat mein badlo",
    logo: "group.png",
    peoples: "2 Million+",
    registrations: "Registtrations",
    logoone: "shield.png",
    peoplesone: "2 Crore+",
    registrationsone: "In winnings Everyday",
    logotwo: "cup.png",
    peoplestwo: "100%",
    registrationstwo: "Safe & Secure",
    paddingTops: "73px",
    paddingBottoms: "48px",
    srcsmall: "smalllogoone.png",
  },
];
export default imagesdata;
