import React from 'react'
import Header from './homepage/Header'

const Nav = () => {
  return (
    <div>
        <Header />
    </div>
  )
}

export default Nav